import { City } from './application';
import { LayoutScheme } from './personalization';
import { ProductInfo } from './product';
import { PromotedContent } from './promotedContent';

export class BusinessCard {
	aboutText: string;
	cardHolderId: string;
	clientAboutText: string;
	clientId: string;
	clientName: string;
	clientType: string;
	clientLinks: LinkContents[];
	groupId: string;
	groupName: string;
	imgUrl: string;
	layoutScheme: LayoutScheme;
	links: LinkContents[];
	footerLinks: LinkContents[];
	name: string;
	productId: string;
	productName: string;
	tagline: string;
	productLogoUrl: string;
	productLogoDestinationUrl: string;
	productLanguage: string;
	promotedContents: PromotedContent[];
	canonicalURL: string;
	createdAt: Date;
	lastUpdatedAt: Date;
	cardholderCity?: City;
	clientCity?: City;
}

export interface LinkContents {
	type?: string;
	url?: string;
}

export interface LinkContentsIcon {
	type: string;
	icon: string;
	show: string;
}

export enum LinkType {
	SAVE_CONTACT = 'SAVE_CONTACT',
	WHATSAPP = 'WHATSAPP',
	FACEBOOK = 'FACEBOOK',
	LINKEDIN = 'LINKEDIN',
	YOUTUBE = 'YOUTUBE',
	EMAIL = 'EMAIL',
	PHONENUMBER = 'PHONENUMBER',
	INSTAGRAM = 'INSTAGRAM',
	TWITTER = 'TWITTER',
	TIKTOK = 'TIKTOK',
	YELP = 'YELP',
	MAPS = 'MAPS',
	WEBSITE = 'WEBSITE'
}

export class LinkUtils {
	static getLinkIcon(linkType: string): string {
		switch (linkType) {
			case LinkType.FACEBOOK:
				return 'fab fa-facebook-f';
			case LinkType.LINKEDIN:
				return 'fab fa-linkedin-in';
			case LinkType.INSTAGRAM:
				return 'fab fa-instagram';
			case LinkType.YOUTUBE:
				return 'fab fa-youtube';
			case LinkType.EMAIL:
				return 'fas fa-envelope';
			case LinkType.PHONENUMBER:
				return 'fas fa-phone-alt';
			case LinkType.WHATSAPP:
				return 'fab fa-whatsapp';
			case LinkType.TWITTER:
				return 'fab mbc-icon-x-twitter';
			case LinkType.TIKTOK:
				return 'fab fa-tiktok';
			case LinkType.YELP:
				return 'fab fa-yelp';
			case LinkType.MAPS:
				return 'fas fa-map-marker-alt';
			case LinkType.WEBSITE:
				return 'fal fa-globe';
			default:
				return linkType;
		}
	}

	static buildLinksArray(p: ProductInfo): TypeButtons[] {
		let result = [
			{
				type: LinkType.WHATSAPP,
				icon: LinkUtils.getLinkIcon(LinkType.WHATSAPP),
				label: 'WhatsApp',
				tempUrl: '',
				selectedButton: 2
			},
			{
				type: LinkType.PHONENUMBER,
				icon: LinkUtils.getLinkIcon(LinkType.PHONENUMBER),
				label: 'personalization.telephone',
				tempUrl: '',
				selectedButton: 3
			},
			{
				type: LinkType.FACEBOOK,
				icon: LinkUtils.getLinkIcon(LinkType.FACEBOOK),
				label: 'Facebook',
				tempUrl: '',
				selectedButton: 4
			},
			{
				type: LinkType.LINKEDIN,
				icon: LinkUtils.getLinkIcon(LinkType.LINKEDIN),
				label: 'LinkedIn',
				tempUrl: '',
				selectedButton: 5
			},
			{
				type: LinkType.INSTAGRAM,
				icon: LinkUtils.getLinkIcon(LinkType.INSTAGRAM),
				label: 'Instagram',
				tempUrl: '',
				selectedButton: 6
			},
			{
				type: LinkType.YOUTUBE,
				icon: LinkUtils.getLinkIcon(LinkType.YOUTUBE),
				label: 'Youtube',
				tempUrl: '',
				selectedButton: 7
			},
			{
				type: LinkType.EMAIL,
				icon: LinkUtils.getLinkIcon(LinkType.EMAIL),
				label: 'E-mail',
				tempUrl: '',
				selectedButton: 8
			},
			{
				type: LinkType.TWITTER,
				icon: LinkUtils.getLinkIcon(LinkType.TWITTER),
				label: 'X/Twitter',
				tempUrl: '',
				selectedButton: 9
			},
			{
				type: LinkType.TIKTOK,
				icon: LinkUtils.getLinkIcon(LinkType.TIKTOK),
				label: 'TikTok',
				tempUrl: '',
				selectedButton: 10
			},
			{
				type: LinkType.YELP,
				icon: LinkUtils.getLinkIcon(LinkType.YELP),
				label: 'Yelp',
				tempUrl: '',
				selectedButton: 11
			},
			{
				type: LinkType.MAPS,
				icon: LinkUtils.getLinkIcon(LinkType.MAPS),
				label: 'Maps',
				tempUrl: '',
				selectedButton: 12
			},
			{
				type: LinkType.WEBSITE,
				icon: LinkUtils.getLinkIcon(LinkType.WEBSITE),
				label: 'Website',
				tempUrl: '',
				selectedButton: 13
			}
		];

		if (p.hideLinkTypes) {
			result = result.filter(r => !p.hideLinkTypes.includes(r.type));
		}

		return result;
	}

	static getLinkHref(linkType: string, url: string): string {
		switch (linkType) {
			case LinkType.EMAIL:
				return `mailto:${url}`;
			case LinkType.PHONENUMBER:
				return `tel:${url}`;
			case LinkType.MAPS:
				return url?.startsWith('https://') ? url : `https://www.google.com/maps/place/${url}`;
			default:
				return url;
		}
	}
}

export enum LinkTypeStatsClick {
	SAVE_CONTACT,
	WHATSAPP,
	FACEBOOK,
	LINKEDIN,
	YOUTUBE,
	EMAIL,
	PHONE_NUMBER,
	INSTAGRAM,
	MBC_ELEMENT,
	TWITTER,
	TIKTOK,
	YELP,
	MAPS,
	WEBSITE,
	LOGO,
	SECONDARY_LOGO
}

export interface StatClick {
	elementId?: string;
	linkType?: LinkTypeStatsClick;
	linkUrl?: string;
}

export class TypeButtons {
	type: LinkType;
	label: string;
	icon: string;
	tempUrl?: string;
	selectedButton?: number;
	isSelected?: boolean;
	isToggle?: boolean;
	isRadioEnabled?: boolean;
}

export interface Button {
	type: string;
	url: string;
}

export enum Type {
	INDIVIDUAL = 'INDIVIDUAL',
	ENTERPRISE = 'ENTERPRISE'
}
export interface TypePersonalization {
	label: string;
	type: Type;
}

export class ClientTypeUtils {
	static getAllClientTypes(): TypePersonalization[] {
		return [
			{ type: Type.INDIVIDUAL, label: 'personalization.typeINDIVIDUAL' },
			{ type: Type.ENTERPRISE, label: 'personalization.typeENTERPRISE' }
		];
	}
}

export enum CardType {
	CLASSIC = 'CLASSIC',
	PORTRAIT = 'PORTRAIT'
}

export class CardTypeUtils {
	static getAllCardTypes(): CardType[] {
		return [CardType.CLASSIC, CardType.PORTRAIT];
	}
}
